import React, {Component} from 'react';

class Header extends Component {
    render() {
        return (
            <div className="w3-center">
                <h2>DS Sports and Therapeutic Massage</h2>
                <h3>Massage Therapist and Yoga Instructor</h3>
            </div>
        );
    }
}

export default Header;
