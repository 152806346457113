import React, { Component } from 'react';

class Find extends Component {
    render() {
        return (
            <div>
                <h4 class="w3-container">Please Select From the Options Below:</h4>
                <div className="w3-card">
                    <header className="w3-container w3-blue">
                        <a href="http://danastrand.co.uk" target="_new">
                            <b>Neil Aitken Physio</b>
                        </a>
                    </header>
                    <div class="w3-container">
                        Prestonfield Golf Club<br />
                        6 Priestfield Road North<br />
                        Edinburgh EH16 5HS<br /><br />
                        t:&nbsp;
                        <a href="tel:07539 440595">07539 440595</a><br />
                        e:&nbsp;
                        <a href="mailto:dana@danastrand.co.uk">Dana Strand</a><br />
                    </div>
                    <br />
                    <footer className="w3-container">
                        <b>Tuesday</b>&nbsp;&nbsp;&nbsp;08.30-11.30
                        <br /><b>Saturday</b>&nbsp;10.00-14.00
                    </footer>
                </div>
                <br />

                <div className="w3-card">
                    <header className="w3-container w3-blue">
                        <a href="http://luxurywellbeing.co.uk" target="_new">
                            <b>Luxury Wellness</b>
                        </a>
                    </header>
                    <div className="w3-container">
                        15 William Street South West Lane<br />
                        Edinburgh EH3 7LL<br /><br />
                        t:&nbsp;
                        <a href="tel:0131 225 6744">0131 225 6744</a>
                        <br />
                        e:&nbsp;
                        <a href="/cdn-cgi/l/email-protection#86efe8e0e9c6eaf3fef3f4fff1e3eaeae4e3efe8e1a8e5e9a8f3ed">Luxury Wellness</a>
                    </div>
                    <footer className="w3-container">
                        <br /><b>Wednesday &amp; Friday</b>
                        <br />Available by appointment
                    </footer>
                </div>
                <br />

                <div className="w3-card">
                    <header className="w3-container w3-blue">
                        <a href="http://www.therap-ease.biz" target="_new">
                            <b>THERAP-EASE Treatment Centre</b>
                        </a>
                    </header>
                    <div className="w3-container">
                        382 Morningside Road<br />
                        Edinburgh EH10 5HX<br />
                        t:&nbsp;
                        <a href="tel:0131 447 9990">0131 447 9990</a><br />
                        e:&nbsp;
                        <a href="/cdn-cgi/l/email-protection#5831363e37182c303d2a3928753d392b3d763a3122">Therap-Ease Treatment Centre</a>
                    </div>
                    <footer className="w3-container">
                        <br />
                        <b>Tuesday</b>&nbsp;&nbsp;&nbsp;14.00-20.00<br />
                    </footer>
                </div>
            </div>
        );
    }
}

export default Find;
