import React, {Component} from 'react';

class Gallery extends Component {
    render() {
        return (
            <div>
                <img Style="max-width:100%" alt="Yoga" src="/img/IMG_6565.JPG"/>
            </div>
        );
    }
}

export default Gallery;
