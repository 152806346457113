import React, { Component } from 'react';
import Iframe from 'react-iframe';
import './App.css';
import Header from './components/Header';
import Gallery from './components/Gallery';
import Blurb from './components/Blurb';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import Find from './components/Find';


class App extends Component {

    componentDidMount() {
        window.location.href = "https://www.sportremedial.com/";
    }

    render() {

        return (
            null
        );
    }
}
export default App;
