import React, {Component} from 'react';

class Blurb extends Component {
    render() {
        return (
            <div>
                <div>
                    Would you like to keep your muscles balanced and flexible with a great range of movement?<br/><br/>
                    Would you like to be pain free again?<br/><br/>
                    Would you like to feel more energetic, or to have better quality sleep?
                </div>

                <div>
                    <h4>...I CAN HELP YOU!...</h4>
                    <h4>DON'T DELAY&nbsp;
                    </h4>
                </div>
            </div>
        );
    }
}

export default Blurb;
